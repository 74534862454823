import React from 'react';
import {  FiInstagram } from 'react-icons/fi';


import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
   

    <div className="app__footer-links">
      

      <div className="app__footer-links_logo">
        <img src={images.gericht} alt="footer_logo" />
        <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p>
        <img src={images.spoon} className="spoon__img" alt='spoon_img' style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
        
          <FiInstagram onClick={(e) => {
      e.preventDefault();
      window.location.href='https://www.instagram.com/lacocinadeauroraa/?locale=us&hl=am-et';}} /> 
        </div>
      </div>

      
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2024 La Cocina de Aurora. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;
import React from 'react';
import { SubHeading } from "../../components"
import {images} from "../../constants"
import './Header.css';



const Header = () => (
  <div className='app__header app__wrapper section__padding' id="home">
    <div className='app__wrapper_info'>
      <SubHeading title="" />
      <h1 className='app__header-h1 headtext__cormorant'>La Cocina De Aurora</h1>
      <p className='p__cormorant ' style={{margin: "rem 0", color:"#5da099"}}>La cocina de Aurora es un establecimiento hostelero especializado en cocina mediterránea. Ofrecemos los servicios de cafetería y restaurante.</p>
      <button type='button'style={{margin:"2rem"}} className='custom__button'onClick={(e) => {
      e.preventDefault();
      window.location.href='/carta';
      }}>Vea Nuestra carta </button>
    </div>

    <div className='app__wrapper_img'>
      <img src={images.welcome1} alt='header img'/>

    </div>

    
  </div>
);

export default Header;

import React from 'react';
import {images} from "../../constants";
import "./MenuEventos.css";



const MenuEventos = () => (
    <div className="app_carta_menu ">
        <img src={images.cartamenu00} alt="carta menu pag0 " />
        <img src={images.cartamenu01} alt="carta menu pag1 " />
        <img src={images.cartamenu02} alt="carta menu pag2 " />
        
        
        

        

    </div>
);
export default MenuEventos;
import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.gericht} alt="app__logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans"><a href="/">Inicio</a></li>
        <li className="p__opensans"><a href="/eventos">Eventos</a></li>
        <li className="p__opensans"><a href="/carta">Menu</a></li>
        <li className="p__opensans"><a href="/contacto">Contacto</a></li>
      </ul>
      
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#7a5c9c" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="/" onClick={() => setToggleMenu(false)}>Inicio</a></li>
              <li><a href="/eventos" onClick={() => setToggleMenu(false)}>Eventos</a></li>
              <li><a href="/carta" onClick={() => setToggleMenu(false)}>Menu</a></li>
              <li><a href="/contacto" onClick={() => setToggleMenu(false)}>Contacto</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
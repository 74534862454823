import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import {  Chef, FindUs, Footer, Gallery, Header, Intro, MenuEventos, Carta } from './container';
import { Navbar } from './components';
import './App.css';

const App = () => (
  <div>
    <Navbar />
    <BrowserRouter>
     <Routes>
      <Route path='/' element={[<Header/>,<Chef/>,<Gallery/>,<Intro />,<FindUs/>]}></Route>
      <Route path='eventos' element={<Chef/>}></Route>
      <Route path='carta' element={<Carta/>}></Route>
      <Route path='contacto' element={<FindUs/>}></Route>
      <Route path='carta' element={<Carta/>}></Route>
      <Route path='menueventos' element={<MenuEventos/>}></Route>
     </Routes>
     </BrowserRouter>
    
     
     
     <Footer />
     
    
  </div>
);

export default App;

import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding" id='eventos'>
   
    <div className="app__wrapper_info">
      <SubHeading title="" />
      <h1 className="headtext__cormorant" >Eventos y Celebraciones</h1>
      

     
      
        <p className="p__cormorant"style={{color: "#5da099" }}> En La cocina de Aurora también estamos especializados en servicios de banquete; comidas de empresa, cócteles, comidas de navidad, bodas, cumpleaños, etc. Disponemos de dos salones más a parte del principal, por si se prefiere un ambiente más íntimo.  </p>
      </div>
      <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.Eventos} alt="restaurant_image" />
    </div>
      <button type='button' className='custom__button'onClick={(e) => {
      e.preventDefault();
      window.location.href='/menueventos';
      }}>Menu Eventos </button>
    

      
    </div>
  
);

export default Chef;
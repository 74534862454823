import React from 'react';
import {images} from "../../constants";
import "./Carta.css";



const Carta = () => (
    <div className="app_carta ">
        <img src={images.carta01} alt="carta entrantes " />
        <img src={images.carta02} alt="carta ensaladas" />
        <img src={images.carta03} alt="carta pescados" />
        <img src={images.carta04} alt="carta carnes" />
        <img src={images.carta05} alt="carta pastas" />
        <img src={images.carta06} alt="carta bebidas1" />
        <img src={images.carta07} alt="carta bebidas2" />
        
        

        

    </div>
);
export default Carta;
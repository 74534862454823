import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.jpeg';
import gallery03 from '../assets/gallery03.jpeg';
import gallery04 from '../assets/gallery04.jpeg';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.jpeg';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import welcome1 from "../assets/welcome1.jpeg";
import Eventos from "../assets/Eventos.jpeg"
import gallery05 from "../assets/gallery05.jpeg";
import gallery06 from "../assets/gallery06.jpeg";
import gallery07 from "../assets/gallery07.jpeg";
import gallery08 from "../assets/gallery08.jpeg";
import carta00 from "../assets/carta00.png";
import carta01 from "../assets/carta01.png";
import carta02 from "../assets/carta02.png";
import carta03 from "../assets/carta03.png";
import carta04 from "../assets/carta04.png";
import carta05 from "../assets/carta05.png";
import carta06 from "../assets/carta06.png";
import carta07 from "../assets/carta07.png";
import cartamenu00 from "../assets/cartamenu00.png";
import cartamenu01 from "../assets/cartamenu01.png";
import cartamenu02 from "../assets/cartamenu02.png";



export default {
  bg,
  chef,
  carta00,
  carta01,
  carta02,
  carta03,
  carta04,
  carta05,
  carta06,
  carta07,
  cartamenu00,
  cartamenu01,
  cartamenu02,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  welcome1,
  Eventos,
};

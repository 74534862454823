import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => (
  <div className='app__bg app__wrapper section__padding' id='contact'>
    <div className='app__wrapper_info'>
      <SubHeading title="Contacto" />
      <h1 className='headtext__cormorant' style={{marginBottom:"3rem"}}>Visitanos</h1>
      <div className='app__wrapper-content'>
        <p className='p__cormorant'style={{color: "#5da099"}}>Baltasar Gracian, 4 , Sevilla</p>
        <p className='p__cormorant'style={{color: "#7a5c9c", margin: "2rem 0"}}>Telefono</p>
        <p className='p__cormorant'style={{color: "#5da099"}}>675 28 44 79</p>
        <p className='p__cormorant'style={{color: "#7a5c9c", margin: "2rem 0"}}>Horario de apertura</p>
        <p className='p__cormorant'style={{color: "#5da099"}}>Lun - Dom: 08:30 am - 16:30 pm</p>
      

      </div>
      <button className='custom__button' onClick={(e) => {
      e.preventDefault();
      window.location.href='https://g.co/kgs/xSRWupN';
      }} style={{marginTop:"2rem"}}  >Visitanos</button> 

      </div>
      <div className='app__wrapper_img'>
        <img src={images.findus} alt='findus' />

      </div>
  </div>
);

export default FindUs;
